/* purgecss start ignore */
/* Normalize Styles */
@import 'normalize.css';

/* Import Modules */
@import '../_modules/link/link';

/* Import Tailwindcss */
@import "tailwindcss/base";

@import "tailwindcss/components";
/* purgecss end ignore */

@import "tailwindcss/utilities";

/* purgecss start ignore */
/* Box sizing partial styles:
   Apply a natural box layout model to all elements */
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a:focus, button:focus{
  outline: none;
}

.is-invalid{
  border: 1px solid #dd244b;
}

.is-invalid + p {
  display: inline;
  color: #dd244b;
}

/* Styling on warning for users on IE7 or below*/
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

#logo{
  width: 100%;
}

.section-1,.section-4{
  background: #ffd537;
  overflow: hidden;
}

.blueTriangle{
  width: 3000px;
  height: 100px;
}

.blueYellowTriangle{
  width: 3000px;
  height: 100px;
}

.cat{
  width:100px;
  top:-40px;
}

.water{
  left: 0;
  width: 200px;
  height: 200px;
  top:52px;
  background: url(../images/water.svg) no-repeat;
  background-size: contain;
}

.howitworks{
  transform:rotate(8deg);
  margin-top: 57px;
}

.cow{
  width: 200px;
  right: -70px;
}

.social-icon {
  width: 30px;
}

.wave {
  background: url(../images/wave.svg) repeat-x;
  width: 4000px;
  height: 150px;
  animation: wave 20s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1000px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0,-25px,0);
  }
  50% {
    transform: translate3d(0,5px,0);
  }
}

@media (min-width:1024px) {
  .howitworks{
    margin-top: 89px;
  }
  .cat{
    width:220px;top:0;
  }
  .cow{
    width: 350px;
    right: -150px;
  }
  #logo{
    width: 450px;
    max-width: 450px;
  }
  .water{
    left: 100px;
    width: 300px;
    height: 300px;
    top:52px;
  }
}
/* purgecss end ignore */